import React from 'react';
import Layout from '../../../components/layout/defaultlayout'
import { Construction } from '../../../components/common/pages'; 

const destination = () => {
      return(
        <>
        <Layout isHome={false}>
          <Construction/>
        </Layout>
        </>
      );
};

export default destination;
